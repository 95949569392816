<template>
  <div id="app">
    <Nav :pt="isIndex" />
    <div class="search">
      <div class="search-left">
        <input type="search" />
        <img class="searchBtn" src="@/assets/home/sear.png" alt="" />
      </div>
      <div class="search-right" @click="menology">
        <img class="search-right-img" src="@/assets/home/rili.png" alt="" />
        <span class="search-right-span">{{ $t("home.showCalendar") }}</span>
      </div>
    </div>
    <swiper ref="mySwiper" id="homeBanner" :options="bannerOptions">
      <swiper-slide v-for="(img, index) in focuslist" :key="index">
        <img @click="gotoPage(img)" :src="`${$baseUrl}${img.thumb}`" />
      </swiper-slide>
      <!-- <swiper-slide>
        <img src="@/assets/home/home.jpg" />
      </swiper-slide> -->
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <!-- 音乐季 -->
    <a href="https://www.sucoch.com/static/season2025/" target="_blank">
      <img
        v-if="$i18n.locale == 'zh'"
        class="musicSeason"
        src="@/assets/home/musicSeason1.png"
      />
      <img v-else class="musicSeason" src="@/assets/home/musicSeason1-en.png" />
    </a>
    <!-- 国际巡演 -->
    <div class="tour">
      <div class="tour-bac">
        <div class="tour-title">{{ $t("home.internationalTour") }}</div>
        <img class="tour-img" src="@/assets/home/tour.png" alt="" />
        <div class="tour-txt" :class="$i18n.locale == 'en' ? 'tour-txt1' : ''">
          {{ $t("home.internationalTourSub") }}
        </div>
      </div>
      <div class="tour-ul">
        <div class="tour-li" v-for="item in tourList" :key="item.id">
          <img class="tour-li-img" :src="item.url" alt="" />
          <div class="tour-li-hz" :style="'background-color:' + item.bacColor">
            <p
              class="tour-li-p1"
              :class="$i18n.locale == 'en' ? '' : 'ellipsis'"
            >
              {{ $i18n.locale == "zh" ? item.title : item.title1 }}
            </p>
            <p
              class="tour-li-p3 ellipsis3"
            >
              {{ $i18n.locale == "zh" ? item.conter : item.conter1 }}
            </p>
            <div class="tour-li-btn">
              <!-- <a
                v-if="item.id == '4'"
                :href="item.pageurl"
                style="color: rgba(255, 255, 255, 0.4); font-size: 10px"
                >{{ $t("home.details") }}</a
              > -->
              <span @click="learn(item)">{{ $t("home.details") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 数字音乐厅 -->
    <div class="home-title">
      <div class="title-beam mr10"></div>
      <div class="title-hz">
        <p class="title-txt">{{ $t("home.digitalConcertHall") }}</p>
        <img
          class="title-txt-img"
          style="width: 116px; height: 22px"
          src="@/assets/home/concertHall.png"
          alt=""
        />
      </div>
      <div class="title-beam ml10"></div>
    </div>
    <div class="concertHall">
      <div class="concertHall-hz">
        <div class="concertHall-left">
          <img
            class="concertHall-img2"
            src="@/assets/home/concertHall-4.png"
            alt=""
          />
          <img
            class="concertHall-img3"
            src="@/assets/home/concertHall-3.png"
            alt=""
          />
        </div>
        <div class="concertHall-right">
          <div class="concertHall-right-top">
            <a href="https://m.dch.suzhousco.com">
              <div class="concertHall-right-access">{{ $t("home.wait") }}</div>
            </a>
          </div>
          <p class="concertHall-right-p p1">{{ $t("home.suzhou") }}</p>
          <p class="concertHall-right-p">{{ $t("home.digitalConcertHall") }}</p>
        </div>
      </div>
    </div>
    <!-- 公益惠民 -->
    <div class="home-title">
      <div class="title-beam mr10"></div>
      <div class="title-hz">
        <p class="title-txt">{{ $t("home.publicBenefit") }}</p>
        <img
          class="title-txt-img"
          style="width: 80px; height: 19px"
          src="@/assets/home/publicBenefit.png"
          alt=""
        />
      </div>
      <div class="title-beam ml10"></div>
    </div>
    <div class="concertHall">
      <div class="publicBenefit-ul">
        <div class="publicBenefit-li" @click="benefit('0')">
          <!-- <a href="https://www.suzhousco.com/#/news/61"> -->
          <img
            class="publicBenefit-li-img"
            src="@/assets/home/community.png"
            alt=""
          />
          <div class="publicBenefit-li-txt">
            <p class="publicBenefit-li-p">
              {{ $t("publicBenefit.concert.text1") }}
            </p>
          </div>
          <!-- </a> -->
        </div>
        <div class="publicBenefit-li" @click="benefit('1')">
          <!-- <a href="https://www.suzhousco.com/#/news/24"> -->
          <div class="publicBenefit-li-txt">
            <p class="publicBenefit-li-p">
              {{ $t("publicBenefit.forum.text1") }}
            </p>
            <!-- <p class="publicBenefit-li-p">导赏音乐会</p> -->
          </div>
          <img
            class="publicBenefit-li-img"
            src="@/assets/home/campus.png"
            alt=""
          />
          <!-- </a> -->
        </div>
        <div class="publicBenefit-li" @click="benefit('2')">
          <!-- <a href="https://www.suzhousco.com/#/news/18"> -->
          <img
            class="publicBenefit-li-img"
            src="@/assets/home/guidedTours.png"
            alt=""
          />
          <div class="publicBenefit-li-txt">
            <p class="publicBenefit-li-p">
              {{ $t("publicBenefit.school.text1") }}
            </p>
          </div>
          <!-- </a> -->
        </div>
      </div>
    </div>
    <!-- 品牌活动 -->
    <div class="home-title">
      <div class="title-beam mr10"></div>
      <div class="title-hz">
        <p class="title-txt">{{ $t("home.brandActivity") }}</p>
        <img
          class="title-txt-img"
          style="width: 97px; height: 19px"
          src="../assets/home/brand.png"
          alt=""
        />
      </div>
      <div class="title-beam ml10"></div>
    </div>
    <div class="concertHall">
      <div class="brand-ul">
        <div
          class="brand-li"
          v-for="pin in pinpaiList"
          :key="pin.id"
          @click="brandDetail(pin.id)"
        >
          <!-- <a class="brand-li" href="https://www.suzhousco.com/#/news/69"> -->
          <div class="brand-li-hz">
            <img
              class="brand-li-img brand-img1"
              src="../assets/home/brand-left.png"
              alt=""
            />
            <img
              class="brand-li-img brand-img2"
              src="../assets/home/brand-right.png"
              alt=""
            />
            <p class="brand-li-p">{{ pin.title }}</p>
          </div>
          <img class="brand-li-img3" :src="`${$baseUrl}${pin.thumb}`" alt="" />
          <!-- </a> -->
        </div>
        <!-- <div class="brand-li" @click="brandDetail('2')">
          <img class="brand-li-img3" src="../assets/home/brand-3.png" alt="" />
          <div class="brand-li-hz">
            <img
              class="brand-li-img brand-img1"
              src="../assets/home/brand-left.png"
              alt=""
            />
            <img
              class="brand-li-img brand-img2"
              src="../assets/home/brand-right.png"
              alt=""
            />
            <p class="brand-li-p">首届江苏苏州民族音乐展演</p>
          </div>
        </div> -->
      </div>
    </div>
    <!-- 新闻资讯 -->
    <div class="home-title">
      <div class="title-beam mr10"></div>
      <div class="title-hz">
        <p class="title-txt">{{ $t("home.news") }}</p>
        <img
          class="title-txt-img"
          style="width: 35px; height: 19px"
          src="../assets/home/News.png"
          alt=""
        />
      </div>
      <div class="title-beam ml10"></div>
    </div>
    <div class="news concertHall">
      <div class="news-ul">
        <div
          class="news-li"
          v-for="(item1, key) in newsList"
          :key="key"
          @click="gotoNewDetail(item1)"
        >
          <div class="news-li-top">
            <div class="news-top-left"></div>
            <div class="news-top-title ellipsis2">{{ item1.title }}</div>
          </div>
          <div class="news-li-bot">
            <p class="news-p1">
              {{ __commonJs["happenTimeFun"](item1.inputtime * 1000, "ymd") }}
            </p>
            <p class="news-p2 f11">附件:</p>
            <p class="news-p2 f11 ellipsis">{{ item1.description }}</p>
            <p class="news-p3 f11">点击阅读更多>></p>
          </div>
        </div>
      </div>
      <div class="news-more-hz">
        <div class="news-more f11" @click="more">{{ $t("home.check") }}</div>
      </div>
    </div>
    <!-- 视频 -->
    <div class="videoBox">
      <!-- <router-link to="/media/">
        <div class="videoPlay"></div>
        <img src="@/assets/p4.jpg" />
      </router-link> -->
      <video
        class="video"
        controls
        poster="@/assets/home/home-2.png"
        src="https://suco.oss-cn-hangzhou.aliyuncs.com/home/web/001.mov"
      >
        your browser does not support the video tag
      </video>
    </div>
    <Footer />
    <Calendar :class="calen ? 'navCut' : ''" @calPath="calPath" />
  </div>
</template>

<style scoped></style>
<script>
import Nav from "@/components/nav.vue";
import Calendar from "@/components/calendar.vue";
import dayjs from "dayjs";
import Footer from "@/components/footer.vue";

export default {
  name: "App",
  components: {
    Nav,
    Calendar,
    Footer,
  },
  data() {
    return {
      isIndex: true,
      calen: false,
      focuslist: [],
      tourList: [
        {
          id: 3,
          title: "2018年 美国展演",
          title1: "Portland, US 2018",
          conter:
            "美国时间2018年7月27日、7月28日晚，成立仅半年多的苏州民族管弦乐团第一次走出国门，在美国波特......",
          conter1:
            'On the evening of July 27 and 28, 2018, the Suzhou Chinese Orchestra, which was established for just over half a year, went abroad for the first time and performed the "Charms ...',
          url: require("../assets/home/america.png"),
          bacColor: "#634636",
          text: "",
          text1: "",
          // pageurl: "https://www.suzhousco.com/#/news/75",
        },
        {
          id: 0,
          title: "2019年 欧洲巡演",
          title1: "Europe tour 2019",
          conter:
            "苏州民族管弦乐团于2019年1月15日—2月10日在德国汉堡、施图尔、波恩、德累斯顿、比利时布鲁塞尔、意大利...",
          conter1:
            "The Suzhou Chinese Orchestra had held Europe tour grand concerts “Charm of Chinese Music” in Hamburg, Stuhl, Bonn, Dresden, Brussels, Como, Liechtenstein, ...",
          url: require("../assets/home/america-2.png"),
          bacColor: "#292929",
          text: "",
          text1: "",
          // pageurl: "https://www.suzhousco.com/#/news/72",
        },
        {
          id: 2,
          title: "2019年 日本巡演",
          title1: "Japan Tour 2019",
          conter:
            "为庆祝新中国成立70周年，纪念中日建交47周年，进一步强化对外宣传工作，推动中国文化“走出去”，促进.....",
          conter1:
            "To celebrate the 70th anniversary of the founding of the People’s Republic of China and commemorate the 47th anniversary of the establishment of diplomatic relations between ...",
          url: require("../assets/home/america-3.png"),
          bacColor: "#AD302B",
          text: "",
          text1: "",
          // pageurl: "https://www.suzhousco.com/#/news/73",
        },
        {
          id: 1,
          title: "2019年 韩国巡演",
          title1: "South Korea Tour 2019",
          conter:
            "为庆祝新中国成立70周年，纪念中韩建交27周年，进一步推动苏州文化“走出去”，应韩国音乐协会、中国音乐......",
          conter1:
            "In order to celebrate the 70th anniversary of the founding of the People’s Republic of China, to commemorate the 27th anniversary of the establishment of diplomatic ...",
          url: require("../assets/home/america-4.png"),
          bacColor: "#6666CC",
          text: "",
          text1: "",
          // pageurl: "https://www.suzhousco.com/#/news/74",
        },
        {
          id: 4,
          title: "2023年 欧洲巡演",
          title1: "Europe tour 2023",
          conter: "苏州民族管弦乐团赴斯洛文尼亚、德国、奥地利3国7城，为欧洲观众带来“来自苏州的声音”",
          conter1:'Suzhou National Orchestra travels to 7 cities in 3 countries, Slovenia, Germany and Austria to bring "Sounds from Suzhou" to European audiences',
          url: require("../assets/home/america-5.png"),
          bacColor: "#E08A00",
          text: "",
          text1: "",
          pageurl: "https://www.suco-europe-tour.com/",
        },
      ],
      hotList: [],
      newsList: [],
      showlist: [],
      screenWidth: 0,
      nowTime: "",
      initDate: [],
      activeShow: null,
      yyhListIndex: 0,

      bannerOptions: {
        autoHeight: true,
        pagination: {
          el: "#homeBanner .swiper-pagination",
          clickable: true,
        },
      },
      yyhOptions: {
        slidesPerView: "auto",
        spaceBetween: 25,
        navigation: {
          nextEl: ".yyhSwiper-next",
          prevEl: ".yyhSwiper-prev",
        },
      },
      userOptions: {
        slidesPerView: 1,
        slidesPerColumn: 2,
        pagination: {
          el: ".homeBox2 .swiper-pagination",
          clickable: true,
        },
      },
      dateList: [],
      pinpaiList: [],
    };
  },

  computed: {
    baseUrl() {
      return this.baseUrl;
    },
    renderDate() {
      let time = this.nowTime.replace(".", "-");
      let d = dayjs(`${time}-01`).daysInMonth();
      return d;
    },
    renderDateData() {
      let d = this.renderDate || 0;
      let showlist = this.showlist || [];
      let initDate = this.initDate || [];
      let result = [];
      for (let i = 1; i <= d; i++) {
        let tem = {};
        let now = dayjs(
          `${this.nowTime.replace(".", "-")}-${i > 9 ? i : "0" + i}`
        ).format("YYYY-MM-DD");
        tem.time = i;
        if (initDate.indexOf(now) > -1) {
          showlist.forEach((item) => {
            let time = dayjs(item.showtime2 * 1000).format("YYYY-MM-DD");
            if (now == time) {
              tem.value = item;
              return;
            }
          });
        }
        result.push(tem);
        // showlist.forEach(item=>{
        //   let time = dayjs(item.showtime2*1000).format('YYYY-MM-DD')
        //   if(initDate.indexOf(time)>-1 && ) {
        //     tem.value = item
        //     return
        //   }else{
        //     tem.value = null
        //   }

        // })
        // result.push(tem)
      }
      return result;
    },
  },
  created: function () {
    this.getInitData();
    this.getShowList();
    this.getNewsList();
    this.pinpai();
    this.nowTime = dayjs().format("YYYY.MM");

    // this.getShowListByTime();
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
  methods: {
    menology() {
      this.calen = true;
    },
    calPath(e) {
      this.calen = e;
    },
    addtocart(e, index) {
      for (var i = 0; i < this.dateList.length; i++) {
        this.dateList[i].active = false;
      }
      this.dateList[index].active = true;
    },

    getNowTime() {
      this.nowTime = dayjs(this.nowTime).format("YYYY.MM");
    },

    getInitData() {
      // 获取焦点图
      this.$ajax.get("/cms/api/focuslist", {}).then((res) => {
        if (res.code == "200") {
          this.focuslist = res.data;
        }
      });
    },

    // 获取演出列表
    getShowList() {
      this.$ajax.get("/cms/api/showlist", {}).then((res) => {
        if (res.code == "200") {
          // this.showlist = res.data;
          this.hotList = res.data.slice(0, 5);
        }
      });
    },

    // 获取新闻列表
    getNewsList() {
      this.$ajax.get("/cms/api/newslist", {}).then((res) => {
        if (res.code == "200") {
          this.newsList = res.data.slice(0, 4);
          //console.log(this.newsList);
        }
      });
    },
    // 国际巡演
    learn(val) {
      if (val.id == "3") {
        this.$router.push({ name: "Usshow" });
      }else if (val.id == "4") {
        this.$router.push({ name: "Europe" });
      } else {
        this.$router.push({ path: "othershow", query: { type: val.id } });
      }
    },
    // 公益慧民
    benefit(type) {
      this.$router.push({ name: "PublicBenefit", query: { type: type } });
    },
    // 品牌活动
    pinpai() {
      this.$ajax.get("/cms/api/pinpailist", {}).then((res) => {
        if (res.code == "200") {
          let data = res.data || [];
          this.pinpaiList = data.slice(0, 2);
        }
      });
    },
    // 更多新闻
    more() {
      this.$router.push({
        path: "news",
      });
    },
    gotoNewDetail(item) {
      this.$router.push({ name: "NewsView", params: { id: item.id } });
    },
    // 品牌活动页面跳转
    brandDetail(item) {
      this.$router.push({ name: "BrandActivity", query: { type: item } });
    },
    // 通过时间查询
    getShowListByTime() {
      let time = this.nowTime
        ? dayjs(`${this.nowTime.replace(".", "-")}-01`).format("YYYY-MM")
        : dayjs().format("YYYY-MM");
      this.$ajax
        .get("/cms/api/showlist", {
          date: time,
        })
        .then((res) => {
          if (res.code == "200") {
            this.showlist = res.data;
            if (res.data.length) {
              let initDate = [];
              res.data.forEach((item) => {
                let time = dayjs(item.showtime2 * 1000).format("YYYY-MM-DD");
                console.log(
                  "tag",
                  dayjs(item.showtime2 * 1000).format("YYYY-MM-DD")
                );
                initDate.push(time);
              });
              console.log("tag1", this.unique(initDate));
              this.initDate = this.unique(initDate);
            } else {
              this.initDate = [];
            }
            // this.hotList = res.data.slice(0,5)
          }
        });
    },

    unique(arr) {
      return Array.from(new Set(arr));
    },

    // 跳转详情
    gotoShowDetail(item) {
      // console.log(item)
      let id = item.id || item.value.id;
      if (id) {
        this.$router.push({ name: `ConcertView`, params: { id: id } });
      }
    },

    gotoMore(item) {
      if (item) {
        if (item.id > 2) {
          this.$router.push({ name: item.path, hash: "#" + item.id });
        } else {
          this.$router.push({ name: item.path });
        }
      }
    },

    templateTime(time) {
      if (!time) return;
      return dayjs(time * 1000).format("YYYY-MM-DD");
    },

    gotoPage(item) {
      if (!item.pageurl) return;
      // let routeUrl = this.$router.resolve({
      //   path: item.pageurl,
      // });
      window.open(item.pageurl, "_blank");
    },
  },
};
</script>


<style>
/* video::-webkit-media-controls-enclosure {
  display: none;
} */
</style>
<style scoped>
#meeting {
  border: none;
  text-align: right;
  background: none;
  width: 45px;
  color: #909399;
}
#app {
  position: relative;
}
.search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  height: 44px;
  padding: 0 16px;
  box-sizing: border-box;
  background-color: #090804;
}
.search-left {
  width: 70%;
  height: 30px;
  border: 1px solid #a9a9a7;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.search input {
  height: 30px;
  background: none;
  border: none;
  color: #fff;
  width: 94%;
  padding: 0 2%;
}
.searchBtn {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 50%;
  margin: -10px 0 0 0;
}
.search-right {
  display: flex;
  align-items: center;
}
.search-right-img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}
.search-right-span {
  color: #ffffff;
  font-size: 12px;
}
.tour {
  width: 100%;
}
.tour-bac {
  width: 100%;
  height: 174px;
  background-image: url("../assets/home/tour-bac.png");
  background-size: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tour-title {
  margin-top: 24px;
  color: #4d4d4d;
  font-size: 18px;
  position: relative;
  font-weight: lighter;
}
.tour-img {
  width: 109px;
  height: 16px;
  position: relative;
  top: -12px;
}
.tour-txt {
  color: #927e73;
  font-size: 12px;
  letter-spacing: 10px;
}
.tour-txt1 {
  letter-spacing: 2px;
}
.tour-ul {
  width: 100%;
  height: 221px;
  padding-left: 16px;
  box-sizing: border-box;
  display: flex;
  overflow-y: hidden;
  overflow-x: auto;
  margin-top: -70px;
  /* background-color: #fff; */
  position: relative;
}
.tour-li {
  width: 165px;
  height: 100%;
  flex-shrink: 0;
  margin-right: 8px;
  text-align: center;
}
.tour-li-img {
  width: 100%;
  height: 97px;
  vertical-align: top;
}
.tour-li-hz {
  width: 100%;
  height: 124px;
  /* background-color: #634636; */
  overflow: hidden;
  padding: 9px;
  box-sizing: border-box;
}
.tour-li-p1 {
  color: #f2f6f7;
  font-size: 14px;
  margin-bottom: 6px;
  text-align: center;
}
.tour-li-p2 {
  color: #f2f6f7;
  font-size: 14px;
  /* line-height: 20px; */
  text-align: center;
}
.tour-li-p3 {
  color: #f2f6f7;
  font-size: 10px;
  line-height: 15px;
}
.tour-li-btn {
  /* width: 60px; */
  height: 20px;
  line-height: 20px;
  text-align: center;
  color: rgba(255, 255, 255, 0.4);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  margin-top: 10px;
  font-size: 10px;
  padding: 0 10px;
  display: inline-block;
}
.musicSeason {
  vertical-align: top;
  margin-top: -1px;
  width: 100%;
}
.home-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
}
.title-beam {
  flex: 1;
  height: 1px;
  background-color: #b3b3b3;
}
.title-hz {
  color: #4d4d4d;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-txt {
  text-align: center;
  font-weight: lighter;
}
.title-txt-img {
  margin-top: -12px;
}
.concertHall {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}
.concertHall-hz {
  width: 100%;
  height: 180px;
  background-color: #ad302b;
  border-radius: 48px 0 48px 0;
  overflow: hidden;
  display: flex;
}
.concertHall-left {
  width: 214px;
  height: 100%;
  position: relative;
}
.concertHall-img2 {
  width: 100%;
  height: 100%;
}
.concertHall-img3 {
  width: 114px;
  height: 29px;
  position: absolute;
  right: -29px;
  bottom: 0;
}
.concertHall-right {
  flex: 1;
}
.concertHall-right-top {
  width: 100%;
  height: 79px;
  background-color: #292929;
  display: flex;
  justify-content: center;
  align-items: center;
}
.concertHall-right-access {
  /* width: 62px; */
  height: 24px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: rgba(255, 255, 255, 0.4);
  font-size: 12px;
  text-align: center;
  line-height: 24px;
  border-radius: 4px;
  padding: 0 10px;
}
.concertHall-right-p {
  color: #f2f6f7;
  font-size: 12px;
  text-align: center;
  line-height: 19px;
}
.p1 {
  margin: 24px 0 4px;
}
.publicBenefit-ul {
  width: 100%;
  height: 246px;
  display: flex;
  justify-content: space-between;
}
.publicBenefit-li {
  width: 32%;
  height: 100%;
  background-color: #634636;
  border-radius: 0 40px 0 40px;
  overflow: hidden;
}
.publicBenefit-li:nth-child(2n) {
  background-color: #1a1a1a;
}
.publicBenefit-li-img {
  width: 100%;
  height: 123px;
  vertical-align: top;
}
.publicBenefit-li-txt {
  width: 100%;
  height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  box-sizing: border-box;
}
.publicBenefit-li-p {
  /* width: 20px; */
  color: #f2f6f7;
  font-size: 14px;
  text-align: center;
}
.brand-ul {
  width: 100%;
  color: #fff;
  font-size: 14px;
}
.brand-li {
  width: 343px;
  height: 138px;
  border-radius: 40px 0 40px 0;
  background-color: #ad302b;
  overflow: hidden;
  display: flex;
  margin: 0 auto;
}
.brand-li:nth-child(2n) {
  background-color: #e08a00;
  margin-top: 8px;
  flex-direction: row-reverse;
}
.brand-li-hz {
  width: 113px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  line-height: 19px;
  flex-shrink: 0;
  padding: 0 5px;
  box-sizing: border-box;
}
.brand-li-p {
  text-align: center;
  font-size: 12px;
  color: #fff;
}
.brand-li-img3 {
  width: 230px;
}
.brand-li-img {
  width: 17px;
  height: 57px;
  position: absolute;
}
.brand-img1 {
  left: 0;
  bottom: 5px;
}
.brand-img2 {
  right: 0;
  top: 5px;
}
.news {
  width: 100%;
  height: 468px;
  background-image: url("../assets/home/news-1.png");
  background-size: 100%;
  overflow: hidden;
  margin-top: -16px;
}
.news-ul {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}
.news-li {
  width: 49%;
  height: 182px;
  background-color: #fff;
  margin-right: 2%;
  overflow: hidden;
  margin-bottom: 9px;
}
.news-li:nth-child(2n) {
  margin-right: 0;
}
.news-li-top {
  display: flex;
  width: 100%;
  height: 42px;
  margin-top: 22px;
}
.news-top-left {
  width: 6px;
  height: 42px;
  background-color: #b59d8f;
  margin-right: 9px;
  flex-shrink: 0;
}
.news-top-title {
  line-height: 21px;
}
.news-li-bot {
  padding: 0 5px 0 15px;
  font-size: 13px;
  color: #8d8d8d;
  line-height: 17px;
}
.news-p1 {
  margin: 8px 0 6px;
}
.news-p2 {
  height: 17px;
  overflow: hidden;
}
.news-p3 {
  color: #656565;
  margin-top: 12px;
}
.news-more-hz {
  width: 100%;
  text-align: center;
}
.news-more {
  margin: 10px auto;
  color: #8d8d8d;
  /* width: 70px; */
  height: 24px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  line-height: 24px;
  border-radius: 2px;
  padding: 0 10px;
  display: inline-block;
}
.videoBox {
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
}
.video {
  width: 100%;
}
</style>
