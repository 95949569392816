<template>
  <div class="calendar">
    <div class="calendar-hz">
      <div class="title">
        <div class="title-left" @click="prev">
          <div class="title-left-triangle"></div>
        </div>
        <div class="title-txt">{{ Year }}.</div>
        <div class="title-txt">{{ Month }}</div>
        <div class="title-left" @click="next">
          <div class="title-right-triangle"></div>
        </div>
      </div>
      <div class="body">
        <div class="body-list-top">
          <div class="days-list" v-for="(cal,ind) in showDayList" :key="ind">{{cal}}</div>
          <!-- <div class="days-list">周二</div>
          <div class="days-list">周三</div>
          <div class="days-list">周四</div>
          <div class="days-list">周五</div>
          <div class="days-list">周六</div>
          <div class="days-list">周日</div> -->
        </div>
        <div class="body-list-bot">
          <div
            class="days-list days-hz"
            v-for="(item, index) in monthList"
            :key="index"
            @click="day(item)"
          >
            <div :class="item.number != null ? 'days-list-pitch' : ''">
              {{ item.data }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="details" v-if="myIndex">
      <div class="details-top">
        <div class="details-top-left">
          {{ __commonJs["happenTimeFun"](myIndex.showtime2 * 1000, "hm") }}
        </div>
        <div class="details-top-right">
          {{ __commonJs["happenTimeFun"](myIndex.showtime2 * 1000, "ymd") }}
        </div>
      </div>
      <div class="details-bot">
        <p class="details-p1 ellipsis">{{ myIndex.title }}</p>
        <div class="details-hz">
          <div>{{ myIndex.venue }}</div>
          <div class="details-hz-but" @click="examine">{{$i18n.locale == "zh"?'查看详情':'check the details'}}>></div>
        </div>
      </div>
    </div>
    <div class="navClose" @click="navClose"></div>
  </div>
</template>

<script>
export default {
  props: {
    calen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      optionCh: null,
      showDayList: [],
      month_olympic: [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      month_normal: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
      month_name: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      Month: "",
      Year: "",
      monthList: [],
      calendarList: [],
      my_date: "",
      my_year: "",
      my_month: "",
      my_day: "",
      myIndex: null,
    };
  },
  mounted() {
    this.my_date = new Date();
    this.my_year = this.my_date.getFullYear();
    this.my_month = this.my_date.getMonth();
    this.my_day = this.my_date.getDate();
    // this.refreshDate();
    this.calendar();
  },
  methods: {
    // 关闭日历
    navClose() {
      this.$emit("calPath", false);
    },
    day(val) {
      this.myIndex = val.number;
    },
    // 查看详情
    examine() {
      if (!this.myIndex.url) return;
      let id = this.myIndex.id;
      if (id) {
        this.$router.push({ name: `ConcertView`, params: { id: id } });
      }
    },
    //获取某年某月第一天是星期几
    dayStart(month, year) {
      var tmpDate = new Date(year, month, 1);
      return tmpDate.getDay();
    },
    //计算某年是不是闰年，通过求年份除以4的余数即可
    daysMonth(month, year) {
      var tmp = year % 4;
      if (tmp == 0) {
        return this.month_olympic[month];
      } else {
        return this.month_normal[month];
      }
    },
    calendar() {
      let obj = {
        date: this.my_year + "-" + this.meet(this.my_month + 1),
      };
      this.calendarList = [];
      this.$ajax.get("/cms/api/showlist", obj).then((res) => {
        if (res.code == "200") {
          res.data.forEach((item) => {
            item.day = item.showtime.split("-")[2];
          });
          this.calendarList = res.data;
          this.myIndex = this.calendarList[0];
          this.refreshDate(this.calendarList);
          this.initGetDateList()
        }
      });
    },
    refreshDate(res) {
      var str = {};
      var number = {};
      this.monthList = [];
      var totalDay = this.daysMonth(this.my_month, this.my_year); //获取该月总天数
      var firstDay = this.dayStart(this.my_month, this.my_year); //获取该月第一天是星期几
      // var myclass;
      if (firstDay == 0) {
        firstDay = 7;
      }
      for (var i = 1; i < firstDay; i++) {
        str = {
          data: "",
          number: null,
        };
        this.monthList.push({ str }); //为起始日之前的日期创建空白节点
      }
      // 循环那天有演出
      for (var k = 1; k <= totalDay; k++) {
        for (var j = 0; j < res.length; j++) {
          if (res[j].day == k) {
            number = res[j];
            break;
          } else {
            number = null;
          }
        }
        str = {
          data: k,
          number: number,
        };
        this.monthList.push(str);
      }
      this.Month = this.month_name[this.my_month]; //设置月份显示
      this.Year = this.my_year; //设置年份显示
    },
    // 上一月
    prev() {
      this.optionCh = null;
      this.my_month--;
      if (this.my_month < 0) {
        this.my_year--;
        this.my_month = 11;
      }
      this.calendar();
    },
    // 下一月
    next() {
      this.optionCh = null;
      this.my_month++;
      if (this.my_month > 11) {
        this.my_year++;
        this.my_month = 0;
      }
      this.calendar();
    },
    // 补零
    meet(val) {
      if (val < 10) {
        return (val = "0" + val);
      } else {
        return val;
      }
    },
    // 可选的日期
    optional(val) {
      let day = this.meet(val.data);
      let month = this.meet(this.my_month + 1);
      let data = this.my_year + "-" + month + "-" + day;
      this.$emit("changeDate", data);
      this.optionCh = val.data;
    },
    // 日历头部
    initGetDateList() {
      if (this.$i18n.locale == "en") {
        this.showDayList = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
      } else {
        this.showDayList = [
          "周一",
          "周二",
          "周三",
          "周四",
          "周五",
          "周六",
          "周日",
        ];
      }
    },
  },
};
</script>

<style scoped>
.calendar {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  background: rgba(22, 22, 22, 0.9);
  color: #fff;
  padding: 15px;
  margin: 0 100% 0 -100%;
  -webkit-transition: 0.2s ease-in 0s;
  -o-transition: 0.2s ease-in 0s;
  transition: 0.2s ease-in 0s;
}
.navCut {
  margin: 0 !important;
}
.calendar-hz {
  width: 100%;
  /* height: 350px; */
  background: #634636;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 100px;
  padding: 16px;
  box-sizing: border-box;
}
.title {
  margin-top: 6px;
  margin-bottom: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.title-txt {
  font-size: 18px;
  line-height: 20px;
}
.title-left {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
.title-left-triangle {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 8px solid #fff;
  border-bottom: 6px solid transparent;
}
.title-right-triangle {
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 8px solid #fff;
  border-bottom: 6px solid transparent;
}
.body {
  color: #fff;
}
.body-list-top {
  width: 100%;
  height: 32px;
  line-height: 32px;
  font-size: 11px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid rgba(255, 255, 255, 0.43);
  border-bottom: 1px solid rgba(255, 255, 255, 0.43);
}
.body-list-bot {
  width: 100%;
  /* line-height: 30px;  */
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  padding-top: 5px;
}
.days-list {
  width: 14.28%;
  line-height: 30px;
  list-style-type: none;
  display: block;
  box-sizing: border-box;
  text-align: center;
}
.days-hz {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  line-height: 24px;
}
.days-list-pitch {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #b49a71;
  font-weight: normal;
}
.bai {
  color: #fff;
  font-weight: lighter;
}
.details {
  margin-top: 30px;
}
.details {
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
}
.details-top {
  padding-bottom: 9px;
  border-bottom: 1px solid #999999;
  display: flex;
}
.details-top-left {
  font-size: 24px;
  color: #fff;
  margin-right: 26px;
}
.details-top-right {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.8);
}
.details-bot {
  padding-top: 12px;
}
.details-p1 {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
}
.details-hz {
  margin-top: 10px;
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.details-hz-but {
  /* width: 72px; */
  padding:0 10px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 4px;
}
.navClose {
  position: absolute;
  width: 20px;
  height: 20px;
  bottom: 60px;
  left: 50%;
}
.navClose::after {
  width: 1px;
  height: 18px;
  content: "";
  display: block;
  background: #fff;
  border-radius: 1px;
  position: absolute;

  -ms-transform: rotate(45deg); /* IE 9 */
  -moz-transform: rotate(45deg); /* Firefox */
  -webkit-transform: rotate(45deg); /* Safari 和 Chrome */
  -o-transform: rotate(45deg);
}
.navClose::before {
  width: 1px;
  height: 18px;
  content: "";
  display: block;
  background: #fff;
  border-radius: 1px;
  position: absolute;

  -ms-transform: rotate(-45deg); /* IE 9 */
  -moz-transform: rotate(-45deg); /* Firefox */
  -webkit-transform: rotate(-45deg); /* Safari 和 Chrome */
  -o-transform: rotate(-45deg);
}
</style>
